<template>
  <a-drawer
      :zIndex="1000"
      :title="dialog.type === 'add'? 'Add New Lot' : 'Edit Lot'"
      :width="'50%'"
      @close="onClose"
      :visible="dialog.show"
      :drawerStyle="{height: 'calc(100% - 50px)',overflow: 'auto'}"
      :maskClosable="false"
    >
      <bh-loading :show="loading" />
      <a-form-model :model="lot" ref="lot" class="h-full dF fC jSB" >

        <div class="f1">
          <a-row :gutter="16">
            <a-col v-if="hasChildren" :span="24">
              <a-form-model :model="parentLot" ref="parentLot">
                <a-form-model-item label="Inherit data from" prop="inherit" required :rules="req('Please select one')">
                  <a-select v-model="parentLot.inherit">
                    <a-select-opt-group v-for="children in childrenLots" :key="children.id">
                      <span slot="label">{{children.name}}</span>
                      <a-select-option v-for="lot in children.lots" :key="lot.id" :value="lot.id">{{lot.name}}{{ lot.block ? ` (Block ${lot.block})` : '' }}</a-select-option>
                    </a-select-opt-group>
                  </a-select>
                </a-form-model-item>
              </a-form-model>
            </a-col>
          </a-row>

            <div v-if="parentLot.inherit && parentLot.inherit != ''">
              <h6 class="ml-3">Lot Details</h6>
              <hr />
              <a-row :gutter="16">
                <a-col :span="12">
                  <a-form-model-item label="Lot #" required>
                    <a-input :disabled="true" :value="childLots[parentLot.inherit].name"></a-input>
                  </a-form-model-item>
                </a-col>
                <a-col :span="12">
                  <a-form-model-item label="Block #">
                    <a-input :disabled="true" :value="childLots[parentLot.inherit].block"></a-input>
                  </a-form-model-item>
                </a-col>
				<a-col :span="12">
                    <a-form-model-item label="Tentative/Firm Close Date" prop="closingDate">
                        <a-date-picker :disabled="true" style="width:100%" :value="getClosingDate(childLots[parentLot.inherit].closingDate)" placeholder="YYYY-MM-DD"></a-date-picker>
                    </a-form-model-item>
                </a-col>
                <a-col :span="12">
                  <a-form-model-item label="Publishing">
                    <a-select :disabled="true" :value="childLots[parentLot.inherit].publishStatus">
                      <a-select-option value="draft">Draft</a-select-option>
                      <a-select-option value="publish">Publish</a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
                <a-col :span="24">
                  <a-form-model-item label="Tags">
                    <a-select :disabled="true" :value="childLots[parentLot.inherit].tags" mode="tags">
                      <a-select-option v-for="tag in tags" :value="tag.id" :key="tag.id">{{tag.name}}</a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
                <a-col :span="8">
                  <a-form-model-item label="Street Number">
                    <a-input :disabled="true" :value="childLots[parentLot.inherit].streetNumber"></a-input>
                  </a-form-model-item>
                </a-col>
                <a-col :span="8">
                  <a-form-model-item label="Street Name">
                    <a-input :disabled="true" :value="childLots[parentLot.inherit].street"></a-input>
                  </a-form-model-item>
                </a-col>
                <a-col :span="8">
                  <a-form-model-item label="Postal Code">
                    <a-input :disabled="true" :value="childLots[parentLot.inherit].postal"></a-input>
                  </a-form-model-item>
                </a-col>
                <a-col :span="12">
                  <a-form-model-item label="Lot Type">
                    <a-input :disabled="true" :value="childLots[parentLot.inherit].modelType"></a-input>
                  </a-form-model-item>
                </a-col>
                <a-col :span="12">
                  <a-form-model-item label="Lot Frontage">
                    <a-input :disabled="true" :value="childLots[parentLot.inherit].size"></a-input>
                  </a-form-model-item>
                </a-col>
                <a-col :span="24">
                  <a-form-model-item label="Choose all Home Models that fit this lot">
                    <a-select :disabled="true" :value="getUnits(childLots[parentLot.inherit].units)" mode="tags">
                      <a-select-option v-for="unit in storeUnits" :value="unit.id" :key="unit.id">{{`${unit.unitGroup.name ? unit.unitGroup.name : ''} ${unit.name}`}}</a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
              </a-row>
              <h6 class="ml-3">Lot Relations</h6>
              <hr />
              <a-row :gutter="16">
                <a-col :span="8">
                  <a-form-model-item label="Lot Front">
                    <a-input :disabled="true" :value="childLots[parentLot.inherit].front && childLots[parentLot.inherit].front != '' && childLots[childLots[parentLot.inherit].front] && childLots[childLots[parentLot.inherit].front].name ? childLots[childLots[parentLot.inherit].front].name : 'None'"></a-input>
                  </a-form-model-item>
                </a-col>
                <a-col :span="8">
                  <a-form-model-item label="Lot Left">
                    <a-input :disabled="true" :value="childLots[parentLot.inherit].left && childLots[parentLot.inherit].left != '' && childLots[childLots[parentLot.inherit].left] && childLots[childLots[parentLot.inherit].left].name ? childLots[childLots[parentLot.inherit].left].name : 'None'"></a-input>
                  </a-form-model-item>
                </a-col>
                <a-col :span="8">
                  <a-form-model-item label="Lot Right">
                    <a-input :disabled="true" :value="childLots[parentLot.inherit].right && childLots[parentLot.inherit].right != '' && childLots[childLots[parentLot.inherit].right] && childLots[childLots[parentLot.inherit].right].name ? childLots[childLots[parentLot.inherit].right].name : 'None'"></a-input>
                  </a-form-model-item>
                </a-col>
              </a-row>
              <h6 class="ml-3">Lot Gradings</h6>
              <hr />
              <a-row :gutter="16">
                <a-col :span="12">
                  <a-form-model-item label="Garage Side">
                    <a-select :disabled="true" :value="childLots[parentLot.inherit].garageSide">
                      <a-select-option v-for="side in garageSide" :key="side.value" :value="side.value">{{side.label}}</a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
                <a-col :span="12">
                  <a-form-model-item label="Risers at Garage">
                    <a-row>
                      <a-col :span="11">
                        <a-input :disabled="true" :value="childLots[parentLot.inherit].garageRiserMin"></a-input>
                      </a-col>
                      <a-col :span="2" class="text-center">
                        to
                      </a-col>
                      <a-col :span="11">
                        <a-input :disabled="true" :value="childLots[parentLot.inherit].garageRiserMax"></a-input>
                      </a-col>
                    </a-row>
                  </a-form-model-item>
                </a-col>
                <a-col :span="12">
                  <a-form-model-item label="Risers at Front">
                    <a-row>
                      <a-col :span="11">
                        <a-input :disabled="true" :value="childLots[parentLot.inherit].frontRiserMin"></a-input>
                      </a-col>
                      <a-col :span="2" class="text-center">
                        to
                      </a-col>
                      <a-col :span="11">
                        <a-input :disabled="true" :value="childLots[parentLot.inherit].frontRiserMax"></a-input>
                      </a-col>
                    </a-row>
                  </a-form-model-item>
                </a-col>
                <a-col :span="12">
                  <a-form-model-item label="Risers at Rear">
                    <a-row>
                      <a-col :span="11">
                        <a-input :disabled="true" :value="childLots[parentLot.inherit].rearRiserMin"></a-input>
                      </a-col>
                      <a-col :span="2" class="text-center">
                        to
                      </a-col>
                      <a-col :span="11">
                        <a-input :disabled="true" :value="childLots[parentLot.inherit].rearRiserMax"></a-input>
                      </a-col>
                    </a-row>
                  </a-form-model-item>
                </a-col>
              </a-row>
            </div>


        </div>

        <div
          :style="{
            position: 'absolute',
            left: 0,
            bottom:0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
          }"
        >
        <div class="dF jSB">
          <div>
            <a-popover title="Are you sure?" trigger="click" v-model="deletePopup">
              <div slot="content">
                  Are you sure? This will delete this Lot
                  <div class="mt-3">
                  <a-button :style="{marginRight: '8px'}" @click="deletePopup = false" :disabled="deleting">
                      No
                  </a-button>
                  <a-button  @click="confirmDelete" type="danger" :disabled="deleting" :icon="deleting? 'loading' : ''">
                      Yes
                  </a-button>
                  </div>


              </div>
              <a-button :disabled="deleting" v-if="dialog.type === 'edit'" type="danger" ghost icon="delete">DELETE LOT</a-button>
          </a-popover>

          </div>
          <div>
            <a-button class="cancel-button" style="width:120px" :style="{marginRight: '8px'}" @click="onClose">
              CANCEL
            </a-button>
            <a-button style="width:120px" type="primary" @click="submitButton">{{dialog.type === 'add'? 'CREATE' : 'UPDATE'}}</a-button>
          </div>
        </div>

        </div>

      </a-form-model>

    </a-drawer>
</template>

<script>
  import {formatNumber} from 'bh-mod'
  import bhLoading from 'bh-mod/components/common/Loading'
  import { TreeSelect } from 'ant-design-vue';
  import moment from "moment"

  export default {
    components:{bhLoading},

    data() {
      return {
        parentLot:{
          inherit:''
        },
        dataSource:[],
        defaultLot:{
          name:'',
          image:'',
          block:'',
          siteplan:this.$store.state.masterplan.appData.id,
          description:'',
          type:'lot',
          premiums:[], //
          shapeId:'',
          shape:{},
          units:[],
          tags:[],
          unitType:'',
          street:'',
          status:'available',
          garageSide:'none',
          frontRiserMin:0,
          frontRiserMax:0,
          garageRiserMin:0,
          garageRiserMax:0,
          wob:true,
          rearRiserMin:0,
          rearRiserMax:0,
          condition:'none',
          notes:'',
          uniqueGrades:[],
		  closingDate: null
        },
        deleting:false,
        deletePopup:false,
        activeKey: '1',
        loading:false,
        lotUnitType:'',
        SHOW_CHILD:TreeSelect.SHOW_CHILD,
        premiums:['Walkout','Look Out','Pie Shape'],
        uniqueGrades:[],
        newPremium:{
          name:'',
          value:'',
        },
        newGrading: {
          name:'',
          value:'',
        },
        wob:'',
        status: [
            {label:'Available',value:'available'},
            {label:'Pending',value:'hold'},
            {label:'Sold',value:'sold'},
            {label:'Inventory',value:'inventory'},
        ],
        condition:[
          {label:'Rear Upgrade',value:'rearUpgrade'},
          {label:'Side and Rear Upgrapde',value:'sideRearUpgrade'},
          {label:'Corner Upgrade',value:'cornerUpgrade'},
          {label:'Walk-out Basement',value:'wob'},
          {label:'No Condition',value:'none'},
          {label:'Other',value:'other'}
        ],
        garageSide: [
          {label: 'None', value: 'none'},
          {label: 'Right', value: 'right'},
          {label: 'Left', value: 'left'},
          {label: 'Center', value: 'center'},
          {label: 'Rear', value: 'rear'},
          {label: 'Other', value: 'other'},
        ],
        lot:{
          name:'',
          image:'',
          block:'',
          siteplan:this.$store.state.masterplan.appData.id,
          type:'',
          description:'',
          type:'lot',
          premiums:[], //
          shapeId:'',
          shape:{},
          units:[],
          tags:[],
          unitType:'',
          street:'',
          status:'available',
          garageSide:'none',
          frontRiserMin:0,
          frontRiserMax:0,
          garageRiserMin:0,
          garageRiserMax:0,
          wob:true,
          rearRiserMin:0,
          rearRiserMax:0,
          condition:'none',
          notes:'',
          uniqueGrades:[],
		  closingDate: null
        }
      }
    },
    watch:{
      parentLot:{
        handler(val) {

        },
        deep:true
      },
      activeKey(val){
        this.openNewFloor = val.key + 0
      },
      dialog:{
        handler(val){
          if (!val.show){
            this.deleting = false
            this.deletePopup = false
            this.activeKey = '1'
            this.loading = false
            this.lotUnitType = ''
            this.newGrading= {
              name:'',
              value:''
            },
            this.newPremium = {
              name:'',
              value:''
            },
            this.lot = JSON.parse(JSON.stringify(this.defaultLot))
          } else if (val.type === 'add'){
              this.lot.shapeId = String(this.pointInfo.point.id)
              this.lot.shape = this.pointInfo.point
          } else if (val.type === 'edit'){
            let obj = JSON.parse(JSON.stringify(this.$store.state.masterplan.editingLot))

            if (obj.hasOwnProperty('inherit') && obj.inherit != '') {
              if(typeof (obj.inherit) == 'object') {
                this.parentLot.inherit = obj.inherit.id
              } else {
                this.parentLot.inherit = obj.inherit
              }
              $.extend(true,this.lot,obj)
            } else {

              let arrayOfProperties = ['siteplan','unitType','left','right','front']

              arrayOfProperties.forEach(x => {
                if (!obj.hasOwnProperty(x)) {
                  return
                }
                if (typeof obj[x] === 'object') {
                  obj[x] = obj[x].id
                }

                if (typeof obj.units === 'object' && obj.units.length && typeof obj.units[0] === 'object') obj.units = obj.units.map(x => x.id)
              })

              let tags = []
              obj.tags.forEach(tag => {
                if (typeof(tag) == 'object') {
                  tags.push(tag.id)
                }
                else if (typeof(tag) == 'string') {
                  tags.push(tag)
                }
              })
			  if(obj.closingDate){
				obj.closingDate = moment(obj.closingDate)
			  }
              obj.tags = tags
              this.lot = JSON.parse(JSON.stringify(this.defaultLot))
              $.extend(true,this.lot,obj)
            }
          }
          this.newTime = Date.now() + ''
        },
        deep:true
      }
    },
    computed:{
        childUnits() {
          return this.$store.state.masterplan.childUnits
        },
        hasChildren() {
          if (Object.keys(this.$store.state.masterplan.children).length != 0 && Object.keys(this.$store.state.masterplan.childLots).length != 0) return true
          else return false
        },
        childrenLots() {
          let children = []
          let usedLots = []
          let storeLots = Object.values(this.$store.state.masterplan.lots)

          storeLots.forEach(lot => {
            if (lot.hasOwnProperty('inherit') && lot.inherit != '' && !usedLots.includes(lot.inherit)) {
              usedLots.push(lot.inherit)
            }
          })

          if (Object.keys(this.$store.state.masterplan.children).length != 0) {
            Object.values(this.$store.state.masterplan.children).forEach(instance => {
              let child = {
                name:'',
                id:'',
                lots:[]
              }
              child.name = instance.name
              child.id = instance.id
              if (instance.siteplan && instance.siteplan.lots && instance.siteplan.lots.length) {
                instance.siteplan.lots.forEach(instanceLot => {
                  if (this.dialog.type == 'add') {
                    if (instanceLot.type == 'lot' && !usedLots.includes(instanceLot.id)) {
                      let lot = instanceLot
                      child.lots.push(lot)
                    }
                  } else if (this.dialog.type == 'edit') {
                    if (instanceLot.type == 'lot') {
                      let lot = instanceLot
                      child.lots.push(lot)
                    }
                  }
                })
              }
              children.push(child)
            })
          }
          return children
        },
        streetName(){
          let streets = []
          let lots = Object.values(this.$store.state.masterplan.lots).forEach(lot => {
            if (lot.street) {
              streets.push(lot.street)
            }
          })
          return streets
        },
        lots(){
          let lotList = []
          Object.values(this.$store.state.masterplan.lots).forEach(lot => {
            if (lot.type != 'info') lotList.push(lot)
          })
          let list = lotList.map( ({name,id:value, inherit}) => {
            if (inherit && inherit != "") {
              let lot = {}
              if (typeof(inherit) == 'object') {
                lot.name = this.childLots[inherit.id].name
              } else {lot.name = this.childLots[inherit].name}
              lot.value = value
              return lot
            } else {
              return {name,value}
            }
          })
          let noneObj = {
            name:"None",
            value:"none"
          }
          list.push(noneObj)
          return list
        },
        childLots() {
          return this.$store.state.masterplan.childLots
        },
        pointInfo(){
            return this.$store.state.masterplan.newLotInfo
        },
        tags(){
          return this.$store.state.masterplan.tags
        },
        instance(){
          return this.$store.state.instance
        },
        appData(){
          return this.$store.state.masterplan.appData
        },
        getTitle(){
          return `Create a new `
        },
        dialog(){
          return this.$store.state.masterplan.lotDrawer
        },
        unitsFiltered(){
          if (!this.lotUnitType) return this.units
          return this.units.filter(x => x.key === this.lotUnitType)
        },
        selectUnits() {
          let units = Object.values(this.$store.state.masterplan.unitGroups)
          let unitList = []
          units.forEach(unit => {
            let u = {
              title:'',
              value:'',
              key:'',
              children:[]
            }
            u.title = unit.name
            u.value = unit.id
            u.key = u.id
            unit.units.forEach(elev => {
              let child = {
              title:'',
              value:'',
              key:''
              }
              child.title = elev.name
              child.value = elev.id
              child.key = elev.id
              u.children.push(child)
            })
            unitList.push(u)
          })
          return unitList
        },
        storeUnits() {
          return this.$store.state.masterplan.units.filter(x => x.unitGroup && x.unitGroup.name)
        },
        units(){
          let storeGroups = this.$store.state.masterplan.unitGroups || []
          let groups = storeGroups.filter(x => x.unitType)
          return groups.map(uG => {
            return {
              title:uG.name,
              value:uG.id,
              key:uG.unitType.id,
              children:uG.units.map(u => ({
                title:`${uG.name} - ${u.name}`,
                value:u.id,
                key:u.id,
              }))
            }
          })

        },
        unitTypes(){
          let unitTypes = this.$store.state.masterplan.unitTypes
          return unitTypes.map( uT => ({ label:`${uT.name} - ${uT.size}'`, value:uT.id}))
        },
    },
    methods: {
      getUnits(units) {
        return units.filter(x => {
          if (this.storeUnits.map(x => x.id).includes(x)) return x
        })
      },
      onSearch(searchText){
        let temp = []
        this.streetName.forEach(street => {
          temp.push(street)
        })
        let unique = [...new Set(temp)];
        if (searchText && searchText.length > 0) {
            this.dataSource = unique.filter(x => {
                x = x.toLowerCase()
                searchText = searchText.toLowerCase()
                return x.includes(searchText)
            });
        }
        else {
            return this.dataSource = []
        }
      },
      noreq:msg=>({required:false}),
      req:msg=>({required:true,message:msg}),
      updateUnits(e){
        this.lotUnitType = e
        this.lot.units = []
      },
	  getClosingDate(date){
		return date && moment(date.closingDate) || null
	  },
      formatNumber,
      deletePremium(item, index){
        let id = item.id
        this.lot.premiums.splice(index,1)
        this.lot.premiums = this.lot.premiums.filter(x => x.id !== id)
      },
      deleteGrading(item, index){
        let id = item.id
        this.lot.uniqueGrades.splice(index, 1)
        this.lot.uniqueGrades = this.lot.uniqueGrades.filter(x => x.id !== id)
      },
      filterOption(input, option) {
        return (
          option.componentOptions.children[0].text.toUpperCase().indexOf(input.toUpperCase()) >= 0
        );
      },
      addNewPremium(){
        if (this.newPremium && !this.newPremium.name.trim()) return this.$message.error('Premium Requires a Name')
        if (this.newPremium && this.newPremium.value !== 0 && !parseFloat(this.newPremium.value)) return this.$message.error('Premium Requires a Price')
        this.lot.premiums.push({
          id:Date.now() + '',
          name:this.newPremium.name,
          value:this.newPremium.value
        })
        this.newPremium = {
          name:'',
          value:'',
        }

      },
      addNewGrading() {
        if (this.newGrading && !this.newGrading.name.trim()) return this.$message.error('Grading Requires a Name')
        if (this.newGrading && this.newGrading.value !== 0 && !parseFloat(this.newGrading.value)) return this.$message.error('Grading Requires a Price')
        this.lot.uniqueGrades.push({
          id: Date.now() +'',
          name: this.newGrading.name,
          value: this.newGrading.value
        })
        this.newGrading = {
          name:'',
          value:'',
        }
      },
      onUnitSelect(val){
        this.lot.units = val
      },
      confirmDelete(){
        this.deleting = true
        this.$api.delete(`/lots/${this.instance.id}/${this.appData.id}/${this.lot.id}`)
          .then( ({data}) => this.onClose({type:'delete', data})).catch(err => {
			if (!err || !err.response || !err.response.status || err.response.status !== 400) {
				this.$message.error(this.$err(err))
			}
		})
      },
      submitButton(){
        if (this.dialog.type === 'add') return this.createLot()
        return this.updateLot()
      },
      tagsToIds(obj){

        let tags = obj.tags || []

        obj.newTags = []
        let oldTags = []

        let self = this

        tags.forEach(tID => {
          if (self.tags.find(x => x.id === tID)) oldTags.push(tID)
          else obj.newTags.push(tID)
        })
        obj.tags = oldTags

        return obj

      },
      validateGradings(){
        let index = this.lot.uniqueGrades.findIndex(x => x.value==null && x.name.trim() || x.value!=null && !x.name.trim())
        if (index != -1) return this.$message.error(`Custom Grading ${index+1} is missing a Name or a Price`)

        for (var i=0; i<this.lot.uniqueGrades.length; i++) {
          if (!this.lot.uniqueGrades[i].name.trim() && this.lot.uniqueGrades[i].value==null) {
            this.lot.uniqueGrades.splice(i,1)
          }
        }
      },
      validatePremiums(){
      },
      createLot(){

        if (!this.hasChildren) {
          if(this.newGrading.name.trim() && !this.newGrading.value.trim()) this.$message.error('Please enter the new grading value')
          if(this.newGrading.value.trim() && !this.newGrading.name.trim()) this.$message.error('Please enter the new grading name')
          if(this.newPremium.name.trim() && !this.newPremium.value.trim()) this.$message.error('Please enter the new premium value')
          if(this.newPremium.value.trim() && !this.newPremium.name.trim()) this.$message.error('Please enter the new premium name')

          let index = this.lot.uniqueGrades.findIndex(x => !x.value.trim() && x.name.trim() || x.value.trim() && !x.name.trim())
          if (index != -1) return this.$message.error(`Custom Grading ${index+1} is missing a Name or a Price`)

          for (var i=0; i<this.lot.uniqueGrades.length; i++) {
            if (!this.lot.uniqueGrades[i].name.trim() && !this.lot.uniqueGrades[i].value.trim()) {
              this.lot.uniqueGrades.splice(i,1)
            }
          }
          this.$refs.lot.validate(valid => {
            if (valid) {
              this.loading = true
              if (this.lot.right == 'none') this.lot.right = null
              if (this.lot.left == 'none') this.lot.left = null
              if (this.lot.front == 'none') this.lot.front = null


              if(this.newGrading.name.trim() && this.newGrading.value.trim()) this.lot.uniqueGrades.push(this.newGrading)

              if(this.newPremium.name.trim() && this.newPremium.value.trim()) this.lot.premiums.push(this.newPremium)
              let sendObj = JSON.parse(JSON.stringify(this.lot))
              let street = sendObj.street && sendObj.street.trim()

			  if(sendObj.closingDate){
				  sendObj.closingDate = (moment(sendObj.closingDate).format('X') * 1000)
			  }
              sendObj.street = street
              sendObj = this.tagsToIds(sendObj)
              sendObj.spots = this.pointInfo.settings.spots
              this.$api.post(`/lots/${this.instance.id}/${sendObj.siteplan}`, sendObj).then( ({data}) => {
                this.loading = false
                this.onClose({type:'add',data})
              }).catch(err => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				}).finally(() => {
					this.loading = false
				})
            } else {
              return false;
            }
          })
          // if instance has children
        } else {
          this.$refs.parentLot.validate(valid => {
            if (valid) {
              this.$refs.lot.validate(valid => {
                if (valid) {
                  let lot = JSON.parse(JSON.stringify(this.lot))
                  let sendObj = {}
                  sendObj.inherit = this.parentLot.inherit
                  if (lot.front == 'none') lot.front = ''
                  if (lot.left == 'none') lot.left = ''
                  if (lot.right == 'none') lot.right = ''
                  sendObj.front = lot.front
                  sendObj.left = lot.left
                  sendObj.right = lot.right
                  sendObj.type = 'lot'
                  sendObj.instance = this.instance.id
				  if(sendObj.closingDate){
					  sendObj.closingDate = (moment(sendObj.closingDate).format('X') * 1000)
				  }

                  let siteplan = this.$store.state.masterplan.appData.id
                  sendObj.siteplan = lot.siteplan
                  sendObj.shapeId = lot.shapeId
                  sendObj.shape = lot.shape
                  this.loading = true
                  this.$api.post(`/lots/${this.instance.id}/${siteplan}`, sendObj).then( ({data}) => {
                    this.loading = false
                    if (typeof (data.inherit) == 'object') {
                      data.inherit = data.inherit.id
                    }
                    this.onClose({type:'add',data})
                  }).catch(err => {
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							this.$message.error(this.$err(err))
						}
					}).finally(() => {
					this.loading = false
				  })
                }
              })
            }
          })
        }
      },
      updateLot(){
        this.$refs.parentLot.validate(valid => {
          if (valid) {
            this.$refs.lot.validate(valid => {
              if (valid) {
                let lot = JSON.parse(JSON.stringify(this.lot))
                let sendObj = {}
                sendObj.id = lot.id
                sendObj.inherit = this.parentLot.inherit
                if (lot.front == 'none') lot.front = null
                if (lot.left == 'none') lot.left = null
                if (lot.right == 'none') lot.right = null
                sendObj.front = lot.front
                sendObj.left = lot.left
                sendObj.right = lot.right
                sendObj.type = 'lot'
                sendObj.instance = this.instance.id
                sendObj.siteplan = lot.siteplan
                if (sendObj.siteplan.id) sendObj.siteplan = sendObj.siteplan.id
                sendObj.shapeId = lot.shapeId
				if(sendObj.closingDate){
					sendObj.closingDate = (moment(sendObj.closingDate).format('X') * 1000)
				}

                this.loading = true
                this.$api.put(`/lots/${this.instance.id}/${sendObj.siteplan}/${sendObj.id}`, sendObj).then( ({data}) => {
                  this.loading = false
                  this.onClose({type:'edit',data})
                }).catch(err => {
					this.loading = false
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						this.$message.error(this.$err(err))
					}
				})
              }
            })
          }
        })
      },
      onClose({type='',data={}}) {
        if (this.dialog.type === 'add' && (!type || type === 'click')){
            let {settings,self,point} = this.pointInfo

            let index = settings.spots.findIndex(x => x.id === point.id)
            settings.spots.splice(index,1)
            self.addAction();
            self.redraw();
        }

        if (type === 'delete') console.log('DELETING', data.id)
        this.$store.dispatch('CLOSE_DRAWER',{type,data})
        $('[data-wcp-editor-toolbar-button-name=select]').click()
        this.$refs.lot.resetFields();
        if(this.hasChildren) {
          this.$refs.parentLot.resetFields();
          this.parentLot.inherit = ""
        }

      },
      onChange: function (checked) {
        this.lot.wob=checked;
      },
    },
  };
</script>
<style scoped>
.print {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>

<style>
.cancel-button.ant-btn {
  border-color: #ece9f1 !important;
  background-color: #ece9f1;
  color: #3f3356;
}
</style>
