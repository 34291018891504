<template>
    <div style="width:100%;">
        <a-modal
            title="Master Plan Base Image"
            :visible="masterplanSettings"
            @ok="changeImage"
            :confirmLoading="loadModal"
            @cancel="$store.commit('CLOSE_SETTINGS')"
            >
            <div class="dF fC jC aC">
                <h5 class="mt-3 mb-3 text-center">Please click below to change the Base Image</h5>
                <div class="dF" style="gap:20px">
                    <div class="dF fC aC jC">
                        <span class="mb-1">Web / Mobile</span>
                        <image-box-selector  v-model="baseImage" :removable="true" type="images"/>
                    </div>
                    <div class="dF fC aC jC">
                        <span class="mb-1">Touchscreen / Table</span>
                        <image-box-selector  v-model="hqImage" :removable="true" type="images"/>
                    </div>
                </div>
            </div>
            <img :src="hqImage" width="0" height="0" class="hq-change" />
            <img :src="baseImage" width="0" height="0" class="baseImage-change" />

        </a-modal>
        <LotDrawer />
        <AmenityDrawer />
        <ColorSettings />
        <div id="wcp-editor" dat-img></div>
    </div>
</template>

<script>
    import LotDrawer from '@/components/siteplan/LotDrawer'
    import ColorSettings from '@/components/siteplan/ColorSettings'
    import ImageBoxSelector from 'bh-mod/components/common/ImageBoxSelector'
    import AmenityDrawer from '@/components/siteplan/AmenityDrawer'
    export default {
        components:{
            LotDrawer,ImageBoxSelector,AmenityDrawer, ColorSettings
        },
        data() {
            return {
                pageLoading:true,
                filter:'all',
                baseImage:'',
                hqImage:'',
                loadModal:false,
            }
        },
        watch:{
            currentModel(val){
                this.filter = 'all'
            },
            masterplanSettings(val){
                if (val){
                    let siteplan =this.$store.state.masterplan.appData
                    console.log('SITEPLAN ', siteplan)
                    this.baseImage = siteplan.image
                    this.hqImage = siteplan.options && siteplan.options.media ? siteplan.options.media.hq : ''
                }
            },
            siteplan(newVal,oldVal){
                console.log('SITEPLAN UPDATED', newVal, oldVal)
                if (oldVal.image !== newVal.image){
                    $.imageMapProEditorDefaults.image.url = newVal.image
                    $.imageMapProEditorDefaults.general.width = newVal.width
                    $.imageMapProEditorDefaults.general.height = newVal.height

                    $.imageMapProEditorDefaults.general.naturalWidth = $.imageMapProEditorDefaults.general.width;
                    $.imageMapProEditorDefaults.general.naturalHeight = $.imageMapProEditorDefaults.general.height;
                    $.image_map_pro_init_editor(undefined, $.WCPEditorSettings);
                }
            }

        },
        computed: {
            seenIntro() {
                return this.$store.state.masterplan.seenIntro
            },
            masterplanSettings(){
                return this.$store.state.masterplan.masterplanSettings
            },
            instance() {
                return this.$store.state.instance
            },
            siteplan(){
                return this.$store.state.masterplan.appData
            },
        },
        methods:{

            startPage() {
                this.$store.commit('INTRO_PAGE')
            },
            changeImage(){
                if (!this.baseImage || this.baseImage == 'https://') return this.$message.error('Please choose a Base Image')
                this.loadModal = true
                let width = document.querySelector('.baseImage-change').naturalWidth
                let height = document.querySelector('.baseImage-change').naturalHeight
                let sendObj = {image:this.baseImage,width,height, options:this.siteplan.options}
                if (this.hqImage){
                    if (!sendObj.options) sendObj.options = {}
                    sendObj.options.media = {
                        hq:this.hqImage,
                        width:document.querySelector('.hq-change').naturalWidth,
                        height:document.querySelector('.hq-change').naturalHeight
                    }
                }
                this.$api.put(`/siteplans/${this.instance.id}/${this.siteplan.id}`, sendObj).then( ({data}) => {
                    this.loadModal = false
                    this.$store.commit('SET_APPDATA_ONLY',data)
                    this.$store.commit('CLOSE_SETTINGS')

                }).catch( err => {
                    let error = this.$err(err)
                    this.$message.error(error)
                })
            },
           setListItems(xxx){
               console.log('=>',xxx)
           }
        },
        created(){

        },
        mounted(){

            window.setListItems = this.setListItems;
            window.siteVue = this
            window.siteStore = this.$store

            $.imageMapProEditorDefaults.spots = this.siteplan.shapes || [];
            $.imageMapProEditorDefaults.image.url = this.siteplan.image
            $.imageMapProEditorDefaults.general.width = this.siteplan.width
            $.imageMapProEditorDefaults.general.height = this.siteplan.height

            $.imageMapProEditorDefaults.general.naturalWidth = $.imageMapProEditorDefaults.general.width;
            $.imageMapProEditorDefaults.general.naturalHeight = $.imageMapProEditorDefaults.general.height;
            this.pageLoading = true
            setTimeout(() => {
                $.image_map_pro_init_editor(undefined, $.WCPEditorSettings);
                this.pageLoading = false
            }, 1000);

        },


    }
</script>
<style lang="scss" module>
@import "./style.module.scss";
</style>
<style lang="scss">

    [role="tablist"]{
        display:none;
    }
    .lot-highlight{
        svg{
            fill: var(--orange) !important;
        }
    }
</style>
